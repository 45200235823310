<template>
  <div class="container">
    <div class="explain">
      <img src="../../../assets/optimizeImgs/warn.png" alt />
      <span>双击单元可进行编辑</span>
      <span>（操作说明：各通称字段请以“，”逗号隔开）</span>
    </div>
    <div class="setBox">
      <div class="title">
        <span>系统字段</span>
        <span>通称字段</span>
      </div>
      <div class="content">
        <div class="item" v-for="(item,index) in setData" :key="index">
          <div class="leftBox" @dblclick="systemDblclick(index)">
            <el-input v-if="systemIndex == index && systemFlag" v-model="item.system" placeholder="请双击单元进行编辑"></el-input>
            <div class="textBox" v-else>{{ item.system }}</div>
          </div>
          <span>=</span>
          <div class="rightBox" @dblclick="appellativeDblclick(index)">
            <el-input v-if="appellativeIndex == index && appellativeFlag" v-model="item.appellative" placeholder="请双击单元进行编辑，以“，”隔开"></el-input>
            <div class="textBox" v-else>{{ item.appellative }}</div>
          </div>
        </div>
      </div>
      <div class="addBox" @click="handleAdd">
        <i class="el-icon-circle-plus-outline"></i>
        <span>添加</span>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  data() {
    return {
      setData:[
        {
          system:'子公司',
          appellative:'各子/分公司，各子分公司，项目申请单位',
        },
        {
          system:'部门',
          appellative:'相关部门，有关部门，涉事部门，职能部门，主管部门，上级部门，项目申请部门',
        },
        {
          system:'岗位',
          appellative:'责任岗位，单位负责人，相关人员',
        },
      ],
      systemFlag:true,
      appellativeFlag:true,
      systemIndex:null,
      appellativeIndex:null,
    };
  },
  created() {},
  methods: {
    systemDblclick(index){
      this.systemIndex = index
      this.systemFlag = !this.systemFlag
    },
    appellativeDblclick(index){
      this.appellativeIndex = index
      this.appellativeFlag = !this.appellativeFlag
    },
    handleAdd(){
      this.systemFlag = false
      this.appellativeFlag = false
      this.systemDblclick(this.setData.length)
      this.appellativeDblclick(this.setData.length)
      this.setData.push({
        system:'',
        appellative:'',
      })
    }
  },
};
</script>
    
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  .explain {
    height: 56px;
    padding-left: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center;
    span:nth-of-type(1) {
      margin-left: 5px;
    }
    span:nth-of-type(2) {
      color: #f23737;
    }
  }
  .setBox {
    width: 100%;
    padding: 0 20px 20px 20px;
    height: calc(100% - 56px);
    overflow-y: auto;
    box-sizing: border-box;
    .title {
      font-family: Microsoft YaHei;
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      :nth-child(1) {
        width: 202px;
        display: inline-block;
      }
      :nth-child(2) {
        margin-left: 28px;
      }
    }
    .content {
      width: 100%;
      margin-top: 20px;
      .item {
        display: flex;
        line-height: 46px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 10px;
        .leftBox {
          width: 202px;
          height: 46px;
          .textBox {
            width: 202px;
            height: 100%;
            background: #e8ebf4;
            border: 1px solid #dcdcdc;
            padding-left: 20px;
            color: #333333;
            box-sizing: border-box;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        span {
          padding: 0 10px;
        }
        .el-input {
          width: 100%;
          height: 100%;
          ::v-deep .el-input__inner {
            width: 100%;
            height: 100% !important;
          }
        }
        .rightBox {
          width: calc(100% - 232px);
          height: 46px;
          .textBox {
            max-width: 100%;
            height: 100%;
            background: #f0f0f0;
            border: 1px solid #dcdcdc;
            padding-left: 20px;
            color: #5a8bff;
            box-sizing: border-box;
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .addBox {
      width: 202px;
      height: 46px;
      border: 1px dashed #dcdcdc;
      box-sizing: border-box;
      font-family: Microsoft YaHei;
      font-weight: 400;
      font-size: 14px;
      color: #5A8BFF;
      display: flex;
      align-items: center;
      padding-left: 50px;
      cursor: pointer;
      i{
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
}
</style>